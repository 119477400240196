@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
html{
    /* background-color:black; */
    /* background-image: url('./background.jpg'); */
    background-color: #0200bd;
}
#count{
    font-size: 1.6rem;
    margin-left: 2.4%;
    padding-top:1.4rem;
}
.clicked{
    color:white;
      background-color:black;
    }
    #heroImg{
        display: block;
        width:100%;
        height:300px;
    }
    .modal {
        display: none; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        padding-top: 100px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
      }
      
      /* Modal Content */
      .modal-content {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 80%;
      }
      
      /* The Close Button */
      .close {
        color: #aaaaaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
      }
      
      .close:hover,
      .close:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
      }
.block{
    display:block;
}
.submit{
    display: block;
}
.App{
    font-size: 1rem;
    width:90%;
    margin-left:5%;
    /* border:2px solid black; */
    background-color:#F5F5F5;
    font-weight: 300;
    /* padding:1rem; */
    font-family:'Roboto'
}
.loginForm{
    text-align: center;
}
.header h3{
    font-weight: 500;
}
.padding{
    padding:1rem;
}
.beg{
    color:white;
    background-image: url('./greaterlife.jpg');
    background-size: contain;

padding:1rem;
}

.beg h1,.beg p{
    margin:1rem 0 1rem 0;

}
#dfsdf{
    word-wrap: normal;
}
.gradeSec{
    font-size:1.8rem;
    margin:1rem 0;
}
.great{
    padding:1rem;
background-color: #0CAC64;
}
.mediocre{
    padding:1rem;
background-color: #E99F0C;
}
.bad{
    padding:1rem;
 background-color: #E71D36;   
}
.header h3{
border-bottom: 3px solid black;
    line-height: 80px;
    font-weight: 500;
    /* margin:.5rem; */
    /* background-color:grey; */
    /* padding:1rem; */
}
.logo{
    height: 200px;
    margin-top:-2rem;
    margin-bottom: -2rem;
}
.r { word-break: break-all;
    line-height: 30px;


}
.r li {
    display: inline-block;
    border:5px solid black;
    margin: 2rem; 
    line-height: 40px;
    font-size: 1.5rem;
}
button{
    font-size:1rem;
}
button{
    background-color:white;
    color:black;
    padding:.5rem 1rem;
    margin:.8rem;
    border:4px solid black;
}
button:hover{
    background-color: black;
    color:white;
}
.beg h1{
    font-weight: 500;
    font-size:2.4rem;
    /* padding:.5rem; */
}
.beg p{
    font-size:1.4rem;
    line-height:30px;
}
form h3{
    font-size:2rem;
    margin:1rem 0;
}
label{
    margin-top:.5rem;
    font-size: 1.3rem !important;
}
input,button{
    margin-bottom:.5rem;
}
.header p{
    font-size:2rem;
}